/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-05-30",
    versionChannel: "nightly",
    buildDate: "2024-05-30T00:08:45.693Z",
    commitHash: "007a5327174bce692412e174492f6a02b98e854a",
};
